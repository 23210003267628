@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  margin: 10;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2, h3, h4 {
  font-family: "Inconsolata", monospace !important;
}



.mainTitle {
  font-family: "Inconsolata", monospace !important;
  display: inline-block;
  color: black;

}

.button, .ui.button {
  font-family: "Inconsolata", monospace !important;
  
}


.item {
  font-family: 'Poppins', sans-serif !important;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table, td, th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 0.2em;
  text-align: center;
  font-family: "Inconsolata", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hexGrid {
  // background-color: #f6fbfc;
  background-color: #e3f3f6;
}

.hexGridHolder {
  overflow: auto;
  background-color: white;
  &::-webkit-scrollbar {
    display: none;
  }
  width: calc(100vw - 50px);
  // max-height: 80vh;
  margin: 0px;
  padding: 0px;
  margin-left: 0;
  margin-right: 0;
  border: 1px #e3f3f6;
}

.ui.container.MainContainer {
  max-width: 100%;
}

.hexChartElement {
  // pointer-events: "none" !important;
  cursor: pointer;
}

.bigHexHolder {
  display: block;
  margin: auto;
}

.bigHexLabelHolderForeignObject {
  pointer-events: none;
}

.bigHexLabelHolder {
  width: 100%;
  height: 100%;
  text-align: center;
  // border: 1px solid red;
  vertical-align: bottom;
}

.ui.label.bigHexCatLabel {
  display: inline-block;
  margin: 0 auto;
  position: fixed;
  min-width: 80%;
  padding: auto;
  left: 50%;
  transform: translate(-50% , 0);
  bottom: 0;
  border-radius: 8px;
  background-color: white;

  &.good {
    border: 1px solid #2A9D8F;
    color: #2A9D8F;
  }
  &.bad {
    border: 1px solid #E76F51;
    color: #E76F51;
  }
}

.hexModalMainLabel {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  padding: 1.2em;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid;
  background-color: white;
  &.neutral {
    border: 1px solid #000;
    color: #000;
    &:hover {
      background-color: rgba(255,255,255,0.3);
    }
  }
  &.good {
    border: 1px solid #2A9D8F;
    color: #2A9D8F;
    &:hover {
      background-color: rgba(42,157,143,0.3);
    }
  }
  &.bad {
    border: 1px solid #E76F51;
    color: #E76F51;
    &:hover {
      background-color: rgba(231,111,81,0.3);
    }
  }
}

.hexModalSecondaryLabel {
  color: black;
  font-weight: lighter;
  font-size: 0.8em;
  padding-top: 0.5em;
}

.questionBar {
  stroke: #000;
  stroke-width: 1;
  stroke-opacity: 0.3;
  fill-opacity: 0;
  fill: #ddd;
}

.scoreCardPageSubHead {
  font-family: "Inconsolata", monospace !important;
  text-decoration: underline;
  padding-top: 2em;
  padding-bottom: 1em;
  text-align: left;
}

.scoreCardPageHead {
  font-family: "Inconsolata", monospace;
  padding-top: 2em;
  text-align: left;
}

.scoreCardPage {
  // margin:40;
  // .row {
  //   // margin: 40;
  // }
}

.scoreCardDescription {
  font-size: 1.1em;
  text-align: left;
  padding-top: 1em;
  padding-bottom: 1em;
  p {
    line-height: 1.8;
  }
}

.scoreCard {
  width: 100%;
  text-align: left;
  .scoreCardContent {
    margin: 5px;
    min-height: 370px;
    .header {
      font-size: 1.1em;
      font-weight: bold;
      color: "red";
      padding: 0.8em 0em;
      min-height: 100px;
    }
    .description {
      font-size: 1em;
      padding: 0.3em 0em;
      &.answerGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .answer {
          font-size: 1.3em;
          font-family: "Inconsolata", monospace;
          font-weight: bold;
          display: inline;
          padding: 0.3em;
          margin: 5px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          &.hoverAnswer {
            border: 1px solid;
            font-weight: bold;
          }
          &.correctAnswer {
            border: 1px solid;
            font-weight: bold;
          }
          &.wrongAnswer {
            border: 1px dashed;
            opacity: 0.5;
          }
        }
      }
    }
    .scoreCardQuote {
      text-align: justify;
    }
    svg {
      cursor: pointer;
    }
  }
}


.quoteCard {
  text-align: left;
  background-color: #ddd !important;
  .quoteCardContent {
    margin: 5px;
    min-height: 370px;
    .header {

      &.quoteCardHeader {

        font-size: 1em !important; 
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 0.2em;
        // padding: 0.8em 0em;
      }
    }
    .description {
      font-size: 1em;
      padding-top: 0.3em;
      padding-bottom: 0.3em;
      
      &.quoteCardQuestion {
        font-size: 1em;
        font-family: "Inconsolata", monospace;
        font-weight: bold;
        margin: 5px;
        padding: 0.3em;
      }
      &.quoteCardAnswer {
        font-size: 1em;
        font-family: "Inconsolata", monospace;
        font-weight: bold;
        padding: 0.3em;
        margin: 5px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid;
        font-weight: bold;
        &.good {
          border: 1px solid #2A9D8F;
          color: #2A9D8F;
          background-color: rgba(42,157,143,0.3);
          font-weight: bold;
        }
        &.bad {
          border: 1px solid #E76F51;
          color: #E76F51;
          background-color: rgba(231,111,81,0.3);
          font-weight: bold;
        }
      }
      &.quoteCardQuote {
        font-family: "Inconsolata", monospace;
        margin: 5px;
        padding: 0.3em;
      }
      &.quoteCardPerson {
        margin: 5px;
        padding: 0.3em;
        text-align: right;
      }
      &.quoteCardReadMore {
        text-align: center;
      }
    }
  }
}

.cardShareHolder {
  margin-top: 5px;
  margin-left: 5px;
}


.simpleIcon {

  // stroke-width: 0.4px;
  fill: none;
  // .cls-1,
  // .cls-2,
  // .cls-3,
  // .cls-4 {
  //   fill: none;
  //   stroke: #231f20;
  //   stroke-miterlimit: 10;
  //   // stroke-width: 0.3px;
  //   fill-rule: evenodd;
  // }
  // .cls-2 {
  //   fill-rule: evenodd;
  // }
}

.flag {
  filter: drop-shadow(0px 0px 1px black)
  //  grayscale(90%);
}

.decoratedString {
  display: inline;
  border: 1px solid;
  font-weight: bold;
  padding-left: 0.3em;
  padding-right: 0.3em;
  border-radius: 0.3em;
  cursor: pointer;
  margin: 2px;
  // white-space:nowrap;
  &.good {
    border: 1px solid #2A9D8F;
    color: #2A9D8F;
    background-color: rgba(42,157,143,0.3);
  }
  &.bad {
    border: 1px solid #E76F51;
    color: #E76F51;
    background-color: rgba(231,111,81,0.3);
  }
  &:hover {
    background-color:#E9C46A;
  }
}

.descriptionStatsBox {
  display: block;
  border: 1px solid;
  // font-weight: bold;
  padding: 0.6em;
  border-radius: 0.3em;
  cursor: pointer;
  .descriptionStatsBoxMainFig {
    font-size: 1.8em;
    line-height: 1.3em;
    font-weight: bold;
    display: inline;
  }
  &.good {
    border: 1px solid #2A9D8F;
    color: #2A9D8F;
    // background-color: rgba(42,157,143,0.3);
  }
  &.bad {
    border: 1px solid #E76F51;
    color: #E76F51;
    // background-color: rgba(231,111,81,0.3);
  }

}

.downloadIcon {
  text-align: right;
}

.logo {
  height: 3em;
  display: inline-block;
  margin: 0em 1em;
  padding: 0;
  &.mobile {
    height: 2em;
    display: inline;
  }
}

.questionContainer {
  overflow: auto;
  padding: 0.3em;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ui.inverted.link.list .item, .ui.inverted.link.list .item a:not(.ui), .ui.inverted.link.list a.item {
  color: #a1bbc4 !important;
  text-decoration: none;
}

.ui.inverted.link.list .item:hover, .ui.inverted.link.list .item a:not(.ui):hover, .ui.inverted.link.list a.item:hover {
  color: #f5f7f8 !important;
}

.socialicon {
  color: #a1bbc4 !important;
  text-decoration: none;
}

.socialicon:hover {
  color: #f5f7f8 !important;
}

.comparisonChartGrid {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  // min-width: 600px;
}

.comparisonChartGridContainer {
  overflow-x: none;
  overflow-y: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.comparisonChartContainerParent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparisonChartContainer {
  text-align: center;
}

